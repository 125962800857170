var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"friend-apply-for-container"},[_c('van-list',{staticClass:"apply-for__inner",attrs:{"finished":_vm.finished,"finished-text":""},on:{"load":_vm.getRecords},model:{value:(_vm.requesting),callback:function ($$v) {_vm.requesting=$$v},expression:"requesting"}},_vm._l((_vm.lists),function(item){return _c('div',{key:item.id,staticClass:"apply__item",attrs:{"data-uid":item.user.uid},on:{"click":function($event){$event.stopPropagation();return _vm.userJump(item.user.uid)}}},[_c('div',{staticClass:"item__option"},[_c('img',{staticClass:"headerpic",attrs:{"src":item.user.headimgurl,"alt":""}})]),_c('div',{staticClass:"item__option"},[_vm._v(_vm._s(item.user.nickname))]),_c('div',{staticClass:"item__option",on:{"click":function($event){$event.stopPropagation();return _vm.onHandler(item)}}},[_c('van-button',{staticClass:"option__status-bar",class:[
          item.detail.status === _vm.inviteStatusMap.undo.val &&
            Number(item.relation) === _vm.relationMap.INVITE
            ? '--bt'
            : '--applying',
          item.detail.status === _vm.inviteStatusMap.done.val ? '--applying' : ''
        ],attrs:{"loading":_vm.currentRequesting === item.id}},[_vm._v(" "+_vm._s(_vm.showStatus(item))+" ")])],1)])}),0),_c('slogan',{attrs:{"text":"没有人是一座孤岛"}}),_c('div',{staticClass:"group__footer-fixed-nav"},[_c('div',{staticClass:"nav__item",on:{"click":_vm.goBack}},[_c('strong',[_vm._v("返回")])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }