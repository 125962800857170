<template>
  <div class="friend-apply-for-container">
    <van-list class="apply-for__inner" v-model="requesting" :finished="finished" finished-text="" @load="getRecords">
      <div class="apply__item" v-for="item in lists" :key="item.id" @click.stop="userJump(item.user.uid)"
        :data-uid="item.user.uid">
        <div class="item__option">
          <img class="headerpic" :src="item.user.headimgurl" alt="" />
        </div>
        <div class="item__option">{{ item.user.nickname }}</div>
        <div class="item__option" @click.stop="onHandler(item)">
          <van-button class="option__status-bar" :class="[
            item.detail.status === inviteStatusMap.undo.val &&
              Number(item.relation) === relationMap.INVITE
              ? '--bt'
              : '--applying',
            item.detail.status === inviteStatusMap.done.val ? '--applying' : ''
          ]" :loading="currentRequesting === item.id">
            {{ showStatus(item) }}
          </van-button>
        </div>
      </div>
      <!-- <div class="apply__item">
        <div class="item__option"></div>
        <div class="item__option">周伯通</div>
        <div class="item__option">
          <div class="option__status-bar --apply --bt">申请</div>
        </div>
      </div>
      <div class="apply__item">
        <div class="item__option"></div>
        <div class="item__option">周伯通</div>
        <div class="item__option">
          <div class="option__status-bar --passed">已通过</div>
        </div>
      </div>
      <div class="apply__item">
        <div class="item__option"></div>
        <div class="item__option">周伯通</div>
        <div class="item__option">
          <div class="option__status-bar --pass --bt">通过</div>
        </div>
      </div>
      <div class="apply__item">
        <div class="item__option"></div>
        <div class="item__option">周伯通</div>
        <div class="item__option">
          <div class="option__status-bar --overtime">已过期</div>
        </div>
      </div> -->
    </van-list>
    <!-- 标语 -->
    <slogan text="没有人是一座孤岛"></slogan>
    <!-- 底部按钮 -->
    <div class="group__footer-fixed-nav">
      <div class="nav__item" @click="goBack"><strong>返回</strong></div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { RELATION_STATUS_MAP, INVITE_STATUS_MAP } from '@/utils/dict';
import slogan from '../common/_slogan.vue';

export default {
  name: 'page-friend-apply-for',
  components: {
    slogan,
  },
  data() {
    return {
      requesting: false,
      finished: false,
      page: 1,
      totalPage: 1,
      lists: [],
      unDoExpiredRecord: [],
      currentRequesting: 0,
      relationMap: RELATION_STATUS_MAP,
      inviteStatusMap: INVITE_STATUS_MAP,
    };
  },
  computed: {
    ...mapState(['user']),
  },
  watch: {
    $route: {
      handler() {
        this.unDoExpiredRecord = [];
      },
      immediate: true,
    },
  },
  mounted() { },
  methods: {
    getRecords() {
      const that = this;
      that.$request
        .get('api/user/invite/records', {
          page: that.page,
        })
        .then((res) => {
          const lists = res.data.list.filter((item) => !!item.user);
          const meta = res.data._meta;
          that.totalPage = meta.pageCount;
          that.lists.push(...lists);
          if (that.page >= that.totalPage) {
            that.finished = true;
          }
          that.page += 1;
        })
        .catch(() => {
          that.finished = true;
        })
        .finally(() => {
          that.requesting = false;
        });
    },
    showStatus(info) {
      const that = this;
      const { relation } = info;
      if (info.detail.status === that.inviteStatusMap.undo.val) {
        return Number(relation) === that.relationMap.INVITE ? '通过' : '申请中';
      }
      return '已通过';
    },
    onJumpDetail(e) {
      const obj = e.currentTarget;
      this.$router.push({
        path: `/friend/${obj.dataset.uid}`,
      });
    },
    onHandler(record) {
      const that = this;
      const { relation } = record;
      if (Number(record.detail.status) === that.inviteStatusMap.undo.val) {
        if (Number(relation) === that.relationMap.INVITE) {
          // 自己是被请求方
          this.applyPass(record);
        }
      } else {
        that.$notify({
          message: '你们已经成为好友',
        });
      }
    },
    applyPass(record) {
      const that = this;
      if (that.currentRequesting === 0) {
        that.currentRequesting = record.id;
        that.$request
          .get(`api/user/invite/accept/${record.detail.id}`, {
            uid: record.user.uid,
          })
          .then((res) => {
            if (res.data === 'SUCCESS') {
              that.$router.push({
                path: `/friend/${record.user.uid}`,
              });
            }
          })
          .finally(() => {
            that.currentRequesting = 0;
          });
      }
    },
  },
};
</script>

<style></style>
